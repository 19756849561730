<script lang="ts">
	import 'uno.css';
	import '@unocss/reset/tailwind.css';
	import Header from '$lib/layouts/header.svelte';
	import { setContext } from 'svelte';

	import { onDestroy, onMount } from 'svelte';
	import { calculateIsDesktop } from '$store/isDesktop';
	import type { PopularDestination } from '$types/popularDestinations.types.js';
	import type { Article } from '$types/articles.types.js';
	import type { Review } from '$types/review.js';
	import MobileMenu from '$components/header/MobileMenu.svelte';
	import { isMenuOpen } from '$store/menu';
	import type { LANGUAGE } from '$lib/constants/locale.js';
	import Consentcookie from '$components/cookie-consent/consentcookie.svelte';
	import { page } from '$app/stores';
	import * as CookieConsent from 'vanilla-cookieconsent';
	import type { SeoMetadataType, PagesType } from '$types/seo.types';
	export let data;

	setContext<PopularDestination[]>('popularDestinations', data.props?.popularDestinations ?? []);
	setContext<Article[]>('blogsInfo', data.props?.blogsInfo ?? []);
	setContext<any>('localizedUrls', data.props?.localizedUrls ?? {});
	setContext<Review[]>('reviews', data.props?.reviews ?? []);
	setContext<Record<string, string>>(
		'page-contents-landing',
		data.props?.pageContent?.landing ?? {}
	);
	setContext<Record<string, string>>('page-contents-faq', data.props?.pageContent?.faq ?? {});
	setContext<Record<string, string>>(
		'page-contents-about-us',
		data.props?.pageContent?.aboutUs ?? {}
	);
	setContext<Record<string, string>>(
		'page-contents-booking',
		data.props?.pageContent?.booking ?? {}
	);
	setContext<Record<string, string>>(
		'page-contents-terms-and-conditions',
		data.props?.pageContent?.termsAndConditions ?? {}
	);
	setContext<Record<string, string>>(
		'page-contents-privacy-policy',
		data.props?.pageContent?.privacyPolicy ?? {}
	);
	setContext<Record<string, string>>(
		'page-contents-site-author',
		data.props?.pageContent?.siteAuthor ?? {}
	);
	setContext<Record<string, string>>(
		'page-contents-contact',
		data.props?.pageContent?.contact ?? {}
	);
	setContext<Record<string, string>>(
		'page-contents-cookie-consent',
		data.props?.pageContent?.cookieConsent ?? {}
	);
	setContext<Record<string, string>>('page-contents-hints', data.props?.pageContent?.hints ?? {});
	setContext<Record<string, string>>(
		'page-contents-toast-messages',
		data.props?.pageContent?.toastMessages ?? {}
	);
	setContext<LANGUAGE>('lang', data.props?.lang ?? '');
	setContext<Record<PagesType, SeoMetadataType>>('seo-metadata', data.props?.seoMetadata ?? {});

	onMount(async () => {
		if (typeof window === 'undefined') return;
		calculateIsDesktop();
		CookieConsent.setLanguage($page.params.lang, true);
		window?.addEventListener('resize', calculateIsDesktop);
	});
	onDestroy(() => {
		if (typeof window === 'undefined') return;
		window?.removeEventListener('resize', calculateIsDesktop);
	});
</script>

<Header />
<MobileMenu isOpen={$isMenuOpen} />
<slot />
<Consentcookie />

<style>
	:global(*) {
		user-select: none;
		-webkit-user-drag: none;
		-webkit-tap-highlight-color: transparent;
		-webkit-touch-callout: none;
		-webkit-user-select: none;
		-khtml-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
	}

	:global(.hide-scrollbar) {
		-ms-overflow-style: none; /* IE and Edge */
		scrollbar-width: none; /* Firefox */
	}
	:global(.hide-scrollbar::-webkit-scrollbar) {
		display: none; /* Chrome, Safari, Opera*/
	}
	:global(.thin-scrollbar::-webkit-scrollbar) {
		width: 10px;
		height: 155px;
	}

	:global(.thin-scrollbar::-webkit-scrollbar-thumb) {
		background: #ababab;
		border-radius: 10px;
		min-height: 100px;

		border: 2px solid transparent;
		background-clip: padding-box;
	}

	:global(.thin-scrollbar::-webkit-scrollbar-thumb:hover) {
		border: 0;
	}

	:global(.thin-scrollbar::-webkit-scrollbar-track) {
		background: transparent;
	}
	:global(.thin-scrollbar::-webkit-scrollbar-thumb:hover ::-webkit-scrollbar-track) {
		background: #ababab;
	}
</style>
