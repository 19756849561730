<script lang="ts">
	import { getContext, onMount } from 'svelte';
	import * as pkg from 'vanilla-cookieconsent';
	const { run } = pkg;
	import getConfig from './cookieconsent.config';
	import 'vanilla-cookieconsent/dist/cookieconsent.css';
	const data = getContext('page-contents-cookie-consent') as Record<string, string>;
	/**
	 * Only run plugin on the client side
	 */
	onMount(() => {
		run(getConfig(data)).then(() => {
			const desc = document.getElementById('cm__desc') as HTMLDivElement;
			if (desc) desc.innerHTML = data['consentModal-description-html'];
		});
	});
</script>
