function disableBodyScroll() {
	if (typeof window === 'undefined' || typeof document === 'undefined') return;
	const scrollPos =
		window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
	document.body.classList.add('disable-scroll');
	document.body.style.top = `-${scrollPos}px`;
	// save scroll pos
	sessionStorage.setItem('scrollPos', scrollPos.toString());
}

function enableBodyScroll() {
	if (typeof window === 'undefined' || typeof document === 'undefined') return;
	document.body.classList.remove('disable-scroll');
	document.body.style.top = '';
	const scrollPos = sessionStorage.getItem('scrollPos');
	window.scrollTo(0, scrollPos ? parseInt(scrollPos, 10) : 0);
}

function handleScroll(event: Event, isOpen: boolean) {
	if (isOpen) {
		event.preventDefault();
		event.stopPropagation();
		window.scrollTo(0, 0);
	}
}

export const ScrollLock = {
	disableBodyScroll,
	enableBodyScroll,
	handleScroll
};
