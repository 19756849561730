<script lang="ts">
	import CloseIcon from '$lib/icons/close-icon.svelte';
	import { darkMode } from '$store/darkMode';
	import { toggleMenu } from '$store/menu';
	import { fade } from 'svelte/transition';
	import WhiteLogo from '$lib/logo/logo-white.svg';
	import BlackLogo from '$lib/logo/logo-black.svg';
	export let isOpen = false;
	import { getContext, onMount } from 'svelte';
	import { ScrollLock } from '$utils/scrollLock';
	import {
		LINK_ABOUT,
		LINK_BOOKING,
		LINK_CONTACT,
		LINK_FAQS,
		LINK_ROOT,
		LINK_PRIVACY_POLICY,
		LINK_TERMS_AND_CONDITIONS,
		LOCALIZELINK,
		LINK_BLOG
	} from '$utils/link';
	import type { LANGUAGE } from '$lib/constants/locale';

	let lang = getContext('lang') as LANGUAGE;
	let t = getContext('page-contents-landing') as Record<string, string>;

	$: if (isOpen) {
		ScrollLock.disableBodyScroll();
	}

	$: if (!isOpen) {
		ScrollLock.enableBodyScroll();
	}

	onMount(() => {
		document.addEventListener('scroll', (e) => ScrollLock.handleScroll(e, isOpen));
		document.addEventListener('keydown', handleEscape);
		return () => {
			if (!document) return;
			document.removeEventListener('scroll', (e) => ScrollLock.handleScroll(e, isOpen));
			document.removeEventListener('keydown', handleEscape);
		};
	});

	const handleLinkClick = () => {
		isOpen = false;
	};

	const handleEscape = (e: KeyboardEvent) => {
		if (e.key === 'Escape') {
			isOpen = false;
		}
	};
</script>

<div
	class="z-1001 flex-col-between fixed top-0 h-full w-full backdrop-blur-[50px]"
	class:open={isOpen}
	class:hidden={!isOpen}
	in:fade|global={{ duration: 600 }}
	out:fade|global={{ duration: 600 }}
>
	<div class="background z--1 bg-grey-100 dark:bg-grey-800 absolute h-full w-full" />
	<div class="mx-auto flex w-full max-w-[1120px] flex-row items-center justify-between p-4">
		<a
			href={LOCALIZELINK(lang, LINK_ROOT)}
			aria-label="89transfers home page"
			on:click={handleLinkClick}
		>
			{#if !$darkMode}
				<img src={BlackLogo} alt="light-logo-icon" class="h-[32px] w-[135px]" />
			{:else}
				<img src={WhiteLogo} alt="dark-logo-icon" class="h-[32px] w-[135px]" />
			{/if}
		</a>

		<div class="nav-right-items flex-between relative gap-4 md:gap-20">
			<div class="nav-links">
				<button
					class="hamburger-icon mr-[20px]"
					on:click={toggleMenu}
					aria-label="Open mobile menu"
				>
					<CloseIcon height={24} width={24} color={$darkMode ? 'white' : 'black'} />
				</button>
			</div>
		</div>
	</div>
	<div class="ml-5 mt-20 flex flex-col gap-2 self-start md:mx-auto">
		<a
			href={LOCALIZELINK(lang, LINK_BOOKING)}
			on:click={handleLinkClick}
			aria-label="89transfers home page"
			class="karla-medium-4xl text-grey-800 md:karla-medium-5xl mt-4 py-4 dark:text-white md:text-center"
			>{t.menu_booking}</a
		>
		<a
			href={LOCALIZELINK(lang, LINK_ABOUT)}
			aria-label="about 89transfers"
			on:click={handleLinkClick}
			class="karla-medium-4xl text-grey-800 md:karla-medium-5xl mt-4 py-4 dark:text-white md:text-center"
			>{t.menu_about}</a
		>
		<a
			href={LOCALIZELINK(lang, LINK_CONTACT)}
			aria-label="contact 89transfers"
			on:click={handleLinkClick}
			class="karla-medium-4xl text-grey-800 md:karla-medium-5xl mt-4 py-4 dark:text-white md:text-center"
			>{t.menu_contact}</a
		>
		<a
			href={LOCALIZELINK(lang, LINK_FAQS)}
			aria-label="89transfers faq"
			on:click={handleLinkClick}
			class="karla-medium-4xl text-grey-800 md:karla-medium-5xl mt-4 py-4 dark:text-white md:text-center"
			>{t.menu_faqs}</a
		>
		<a
			href={LOCALIZELINK(lang, LINK_BLOG)}
			aria-label="89transfers faq"
			on:click={handleLinkClick}
			class="karla-medium-4xl text-grey-800 md:karla-medium-5xl mt-4 py-4 dark:text-white md:text-center"
			>{t.menu_blog}</a
		>
	</div>
	<div class="flex-col-center h-full gap-5 md:mx-auto">
		<!-- TEMP: hidden -->
		<!-- <div class="block md:hidden">
			<ThemeSwitcher />
		</div> -->
		<a
			href={LOCALIZELINK(lang, LINK_PRIVACY_POLICY)}
			aria-label="Read 89transfers privacy policy"
			class="rubik-regular-base text-grey-600 dark:text-grey-300 underline"
			on:click={handleLinkClick}>{t.menu_privacy_policy}</a
		>
		<a
			href={LOCALIZELINK(lang, LINK_TERMS_AND_CONDITIONS)}
			aria-label="Read 89transfers terms and conditions"
			class="rubik-regular-base text-grey-600 dark:text-grey-300 underline"
			on:click={handleLinkClick}>{t.menu_terms_and_conditions}</a
		>
	</div>
</div>

<style>
	.background {
		mask: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 1) 25%,
			rgba(255, 255, 255, 0.1) 35%,
			rgba(255, 255, 255, 0) 100%
		);
		-webkit-mask: linear-gradient(
			to bottom,
			rgba(255, 255, 255, 1) 25%,
			rgba(255, 255, 255, 0.1) 35%,
			rgba(255, 255, 255, 0) 100%
		);
	}
</style>
